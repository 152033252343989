import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

const useInvestorDashboard = () => {
  const { sdk } = useContext(AppContext)

  const { account } = useWeb3React()

  const { chain } = useConnectionState()

  const { data, error, mutate } = useSWR(
    sdk && account ? ['investorDashboard', account, chain] : null,
    async () => {
      return await sdk.dashboard.getInvestorDashboard(account, 1705968000)
    }
  )

  if (error) {
    console.error(error)
  }

  // Optimistically update the dNFTs as burned
  const optimisticallyBurnDNfts = async (
    vaultAddress: string,
    dNftIds: string[]
  ) => {
    await mutate((previousData) => {
      const newData = { ...previousData }

      // Access the nested vaults array
      const vaultsArray = newData.vaults?.vaults

      if (vaultsArray) {
        const updatedVaults = vaultsArray.map((vault) => {
          // Find the relevant vault using the address
          if (vault.address === vaultAddress) {
            // Update the dNFTs within the found vault
            const updatedDNfts = vault.dNfts.map((dNft) => {
              if (dNftIds.includes(dNft.nftId) && !dNft.isBurned) {
                return { ...dNft, isBurned: true }
              }
              return dNft
            })
            return { ...vault, dNfts: updatedDNfts }
          }
          return vault
        })

        // Update the nested vaults structure with the updated vaults array
        newData.vaults = { ...newData.vaults, vaults: updatedVaults }
      }
      return newData
    }, false)
  }

  // Optimistically update the dNFTs as burned
  const optimisticallyBurnWNFTs = async (vaultAddress: string) => {
    await mutate((investorDashboard) => {
      const vaults =
        investorDashboard.vaults && investorDashboard.vaults.vaults
          ? investorDashboard.vaults.vaults
          : []
      return {
        ...investorDashboard,
        vaults: {
          ...investorDashboard.vaults,
          vaults: [
            ...vaults.filter(({ address }) => address !== vaultAddress),
            {
              ...vaults.find(({ address }) => address === vaultAddress),
              wNfts: [],
            },
          ],
        },
      }
    }, false)
  }

  return {
    metrics: data?.metrics,
    balanceHistory: data?.depositsHistory,
    vaults: data?.vaults?.vaults,
    totalUserBalance: data?.totalUserBalance,
    totalUserRewards: data?.totalUserRewards,
    assetAllocation: data?.assetAllocation,
    investorDashboard: data,
    isLoading: (!error && !data) || !!data?.vaults?.vaults?.length,
    mutate,
    error: error,
    optimisticallyBurnDNfts,
    optimisticallyBurnWNFTs,
  }
}

export default useInvestorDashboard
