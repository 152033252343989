import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  useTheme,
} from '@mui/material'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

interface StepIconProps {
  active: boolean
  completed: boolean
}

const CustomStepIcon: React.FC<StepIconProps> = ({ active, completed }) => {
  const theme = useTheme()

  return (
    <div
      style={{
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: active
          ? theme.palette.primary.main
          : completed
          ? 'green'
          : 'gray',
      }}
    />
  )
}

const CreateMetavaultStepper = () => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { steps } = useCreateMetavaultState()

  const { currentStep } = useCreateMetavaultState()

  return (
    <Stepper
      activeStep={currentStep - 1}
      sx={{
        width: '100%',
        justifyContent: 'center',
        py: 3,
        mb: 2,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      connector={<StepConnector sx={{ maxWidth: '30px' }} />}
    >
      {steps.map((step, index) => (
        <Step key={step.name} completed={step.completed}>
          <StepLabel
            sx={{
              '& .MuiStepLabel-label': {
                fontSize: belowPhabletBreakpoint ? '10px' : '14px',
              },
            }}
            StepIconComponent={(props) => (
              <CustomStepIcon
                {...props}
                active={index === currentStep - 1}
                completed={step.completed}
              />
            )}
          >
            {step.name}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default CreateMetavaultStepper
