import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'

import { SortType } from '@/types/table'

export type MetavaultStepData = {
  id: string
  name: string
  completed: boolean
}

export interface CreateMetavaultState {
  steps: MetavaultStepData[]
  currentStep: number
  asset: string
  selectedVaults: CreateMetaVaultVaults[]
  vaultsSort: SortType<CreateMetaVaultVaults>
  selectedVaultsAllocations: number[]
  vaultName: string
  vaultSymbol: string
  searchString: string
}

export interface CreateMetavaultContextProps extends CreateMetavaultState {
  setCurrentStep: (currentStep: number) => void
  setStepCompletedStatus: (stepNumber: number, status: boolean) => void
  setAsset: (address: string) => void
  setSelectedVaults: (selectedVaults: CreateMetaVaultVaults[]) => void
  handleSortClick: (
    key: keyof CreateMetaVaultVaults,
    strategiesSort: SortType<CreateMetaVaultVaults>
  ) => void
  setSelectedVaultsAllocations: (selectedVaultsAllocations: number[]) => void
  setVaultName: (vaultName: string) => void
  setVaultSymbol: (vaultSymbol: string) => void
  resetAll: () => void
  setSearchingString: (searchString: string) => void
}

export enum CreateMetavaultActionTypes {
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_STEP_COMPLETED = 'SET_STEP_COMPLETED',
  SET_ASSET = 'SET_ASSET',
  SET_SELECTED_VAULTS = 'SET_SELECTED_VAULTS',
  SET_VAULTS_SORT = 'SET_VAULTS_SORT',
  CHANGE_VAULTS_SORT_DIRECTION = 'CHANGE_VAULTS_SORT_DIRECTION',
  SET_SELECTED_VAULTS_ALLOCATIONS = 'SET_SELECTED_VAULTS_ALLOCATIONS',
  SET_VAULT_NAME = 'SET_VAULT_NAME',
  SET_VAULT_SYMBOL = 'SET_VAULT_SYMBOL',
  RESRET_ALL = 'RESET_ALL',
  SET_SEARCH_STRING = 'SET_SEARCH_STRING',
}

export type CreateMetavaultAction =
  | {
      type: CreateMetavaultActionTypes.SET_CURRENT_STEP
      payload: number
    }
  | {
      type: CreateMetavaultActionTypes.SET_STEP_COMPLETED
      payload: {
        stepNumber: number
        status: boolean
      }
    }
  | {
      type: CreateMetavaultActionTypes.SET_ASSET
      payload: string
    }
  | {
      type: CreateMetavaultActionTypes.SET_SELECTED_VAULTS
      payload: CreateMetaVaultVaults[]
    }
  | {
      type: CreateMetavaultActionTypes.SET_VAULTS_SORT
      payload: {
        key: keyof CreateMetaVaultVaults
        direction: SortOrder
      }
    }
  | {
      type: CreateMetavaultActionTypes.CHANGE_VAULTS_SORT_DIRECTION
      payload: {
        key: keyof CreateMetaVaultVaults
        direction: SortOrder
      }
    }
  | {
      type: CreateMetavaultActionTypes.SET_SELECTED_VAULTS_ALLOCATIONS
      payload: number[]
    }
  | {
      type: CreateMetavaultActionTypes.SET_VAULT_NAME
      payload: string
    }
  | {
      type: CreateMetavaultActionTypes.SET_VAULT_SYMBOL
      payload: string
    }
  | {
      type: CreateMetavaultActionTypes.RESRET_ALL
    }
  | {
      type: CreateMetavaultActionTypes.SET_SEARCH_STRING
      payload: string
    }
