import { Box, TextField, Typography } from '@mui/material'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import getLocale from '@/locales/getLocale'

const BasicInfo = () => {
  const t = getLocale()

  const { vaultName, setVaultName, vaultSymbol, setVaultSymbol } =
    useCreateMetavaultState()

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value
    setVaultName(newName)
  }

  const handleSymbolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSymbol = event.target.value
    setVaultSymbol(newSymbol)
  }

  return (
    <Box display='flex' flexDirection='column' gap={1} sx={{ pb: 12 }}>
      <Typography variant='subtitle1'>
        {t('components.organisms.createMetavault.basicInfo.title')}
      </Typography>
      <TextField
        fullWidth
        label={t(
          'components.organisms.createMetavault.basicInfo.nameTextField.label'
        )}
        sx={{ fontWeight: 500 }}
        autoFocus
        helperText={t(
          'components.organisms.createMetavault.basicInfo.nameTextField.helperText'
        )}
        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
        value={vaultName}
        onChange={handleNameChange}
      />

      <TextField
        fullWidth
        label={t(
          'components.organisms.createMetavault.basicInfo.symbolTextField.label'
        )}
        sx={{ fontWeight: 500, mt: 2 }}
        helperText={t(
          'components.organisms.createMetavault.basicInfo.symbolTextField.helperText'
        )}
        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
        value={vaultSymbol}
        onChange={handleSymbolChange}
      />
    </Box>
  )
}

export default BasicInfo
