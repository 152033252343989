import { IncentivizedRewards, SortOrder } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

import { MUTATE_SLEEP_DURATION } from '@/constants'
import { sleep } from '@/utils/web3'

const useCreatorDashboard = () => {
  const { sdk } = useContext(AppContext)

  const { chain } = useConnectionState()

  const { account } = useWeb3React()

  const { data, error, mutate } = useSWR(
    sdk && account ? ['creatorDashboard', account, chain] : null,
    async () => {
      let vaultIncentives

      try {
        vaultIncentives = await sdk.smartVault.getIncentivizeVaults({
          offset: 0,
          limit: 100,
          sortBy: 'name',
          sortOrder: SortOrder.DESC,
          owner: account.toLowerCase(),
        })
      } catch {
        vaultIncentives = { incentivizedVaults: [] }
      }

      const creatorDashboard = await sdk.dashboard.getCreatorDashboard(
        account,
        1705968000
      )

      return {
        ...creatorDashboard,
        vaults: {
          ...creatorDashboard.vaults,
          vaults: creatorDashboard.vaults.vaults.map((creatorSmartVault) => {
            return {
              ...creatorSmartVault,
              ...vaultIncentives.incentivizedVaults.find(
                ({ address }) => creatorSmartVault.address === address
              ),
            }
          }),
        },
      }
    }
  )

  const updateIncentivizedRewards = async (
    vaultAddress: string,
    isAdd: boolean,
    previousValue?: IncentivizedRewards,
    targetToken?: string
  ) => {
    if (!previousValue) {
      return mutate()
    }

    let retries = 0

    if (isAdd) {
      do {
        const currentValue = await sdk.smartVault.getVaultRewards(
          vaultAddress,
          account.toLowerCase()
        )

        if (currentValue?.rewards.length !== previousValue?.rewards.length) {
          await mutate((creatorVault) => {
            return {
              ...creatorVault,
              vaults: {
                ...creatorVault.vaults,
                vaults: creatorVault.vaults.vaults.map((vault) => {
                  if (vault.address !== vaultAddress) {
                    return vault
                  }
                  return { ...vault, incentivizedRewards: currentValue }
                }),
              },
            }
          })
          break
        }

        retries++
        await sleep(MUTATE_SLEEP_DURATION)
      } while (retries < 5)
    }

    if (!isAdd) {
      do {
        const currentValue = await sdk.smartVault.getVaultRewards(
          vaultAddress,
          account.toLowerCase()
        )

        const previousAmount = previousValue?.rewards.find(
          ({ asset }) => asset.address === targetToken
        )?.endTime
        const currentAmount = currentValue?.rewards.find(
          ({ asset }) => asset.address === targetToken
        )?.endTime

        if (currentAmount !== previousAmount) {
          await mutate((creatorVault) => {
            return {
              ...creatorVault,
              vaults: {
                ...creatorVault.vaults,
                vaults: creatorVault.vaults.vaults.map((vault) => {
                  if (vault.address !== vaultAddress) {
                    return vault
                  }
                  return { ...vault, incentivizedRewards: currentValue }
                }),
              },
            }
          })
          break
        }

        retries++
        await sleep(MUTATE_SLEEP_DURATION)
      } while (retries < 5)
    }
  }

  if (error) {
    console.error(error)
  }

  return {
    creatorDashboard: data,
    tvrHistory: data?.tvrHistory,
    feesHistoryData: data?.feesEarnedHistory,
    metrics: data?.metrics,
    vaults: data?.vaults.vaults,
    totalVaults: data?.vaults.totalCount,
    totalTvr: data?.totalTvr,
    totalDeposits: data?.totalDeposits,
    isLoading: !error && !data,
    updateIncentivizedRewards,
    error,
  }
}

export default useCreatorDashboard
