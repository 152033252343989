import { useEffect } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'

import CreateMetavaultRenderStep from '@/components/organisms/CreateMetavault/CreateMetavaultRenderStep'

import { Routes } from '@/constants/routes'

const CreateMetavault = () => {
  const { chain } = useConnectionState()

  const { currentStep, steps } = useCreateMetavaultState()

  const { setPathWithHistory } = useUniversalNavigation()

  useEffect(() => {
    setPathWithHistory(
      Routes(chain).createMetavault[steps[currentStep - 1].id].url
    )
  }, [currentStep])

  return <CreateMetavaultRenderStep step={currentStep} />
}

export default CreateMetavault
