import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Button, Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

import { Routes } from '@/constants/routes'

const CreateMetavaultSuccess = () => {
  const t = useLocale()
  const { closeModal } = useModalState()
  const { chain } = useConnectionState()
  const navigate = useNavigate()
  const { resetAll } = useCreateMetavaultState()

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={2}
      px={2}
      alignContent='center'
      sx={{ maxWidth: '400px' }}
    >
      <CheckCircleOutlineIcon
        sx={{
          alignSelf: 'center',
          width: '48px',
          height: '48px',
          color: '#2E7D32',
        }}
      />
      <Typography variant='subtitle1' textAlign='center'>
        {t('components.organisms.modals.createMetavaultSuccessModal.title')}
      </Typography>
      <Box textAlign='center'>
        <Typography variant='subtitle1'>
          {t(
            'components.organisms.modals.createMetavaultSuccessModal.description'
          )}{' '}
          <Link
            href={Routes(chain).manager.creator.url}
            sx={{ textDecoration: 'none' }}
          >
            {t('components.organisms.modals.createMetavaultSuccessModal.link')}
          </Link>
        </Typography>
      </Box>
      <Button
        variant='contained'
        onClick={() => {
          navigate(`${Routes(chain).manager.creator.url}`)
          resetAll()
          closeModal(Modals.ACTION_MODAL)
        }}
        sx={{ width: 'auto', alignSelf: 'center' }}
      >
        {t('components.organisms.modals.createMetavaultSuccessModal.button')}
      </Button>
    </Box>
  )
}

export default CreateMetavaultSuccess
