import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, TextField } from '@mui/material'
import { memo, useCallback } from 'react'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import getLocale from '@/locales/getLocale'

const SearchField = memo(() => {
  const t = getLocale()

  const { searchString, setSearchingString } = useCreateMetavaultState()

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchingString(event.target.value)
    },
    [setSearchingString]
  )

  return (
    <TextField
      value={searchString}
      onChange={handleSearchChange}
      variant='outlined'
      size='small'
      placeholder={t(
        'components.organisms.createMetavault.vaults.vaultSelector.search'
      )}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
})

export default SearchField
