import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import mainnet from '@/config/mainnet/arbitrum.contracts.json'
import { tokenAddresses } from '@/constants/tokens'

export const ARBITRUM_CONFIG = {
  RPC_PROVIDER: 'https://arb1.arbitrum.io/rpc',
  CORE_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spool-v2-arbitrum/api',
  API_URL: 'https://jobs.v2.spool.fi/graphql',
  FAST_WITHDRAW_API_URL: 'https://fastwithdraw.v2.spool.fi/',
  STAKING_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spoolfi-staking/api',
  ANALYTICS: 'https://analytics.spool.fi',
  SNAPSHOT_SUBGRAPH: 'https://hub.snapshot.org/graphql',
  SNAPSHOT_HUB: 'https://hub.snapshot.org',
  SNAPSHOT_SPACE: 'egov.spool.eth',
  REWARDS_URL: 'https://rewards.v2.spool.fi/arbitrum',
  PRICE_FEED: 'https://pricefeed.v2.spool.fi/',
  SMART_VAULT_MANAGER: mainnet.SmartVaultManager.proxy,
  DEPOSIT_SWAP_MANAGER: mainnet.DepositSwap.proxy,
  DEPOSIT_MANAGER: mainnet.DepositManager.proxy,
  SMART_VAULT_FACTORY: mainnet.SmartVaultFactory,
  REWARD_MANAGER: mainnet.RewardManager.proxy,
  SPOOL_STAKING: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
  VO_SPOOL: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
  SPOOL_LENS: mainnet.SpoolLens.proxy,
  SPOOL_TOKEN: tokenAddresses[SupportedNetworks.ARBITRUM]['SPOOL'],
  LINEAR_ALLOCATION_PROVIDER: mainnet.LinearAllocationProvider.proxy,
  ONE_INCH_ROUTER: '0x111111125421ca6dc452d289314280a0f8842a65',
  SWAPPER: mainnet.Swapper.proxy,
  ALLOW_LIST_GUARD: mainnet.AllowlistGuard,
  IGUARD_MANAGER: mainnet.GuardManager.proxy,
  STRATEGY_REGISTRY: mainnet.StrategyRegistry.proxy,
  API_CHAIN_NAME: 'arbitrum',
  SPOOL_ACCESS_CONTROL: mainnet.SpoolAccessControl.proxy,
  META_VAULT_FACTORY: mainnet.MetaVaultFactory,
}
