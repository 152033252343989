import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import getLocale from '@/locales/getLocale'

const t = getLocale()

export enum BaseRoutesPaths {
  CREATOR = '/manager/creator',
  CREATE_VAULT = '/manager/create',
  CREATE_METAVAULT = '/manager/create-metavault',
  INCENTIVES = '/manager/incentives',
  HISTORY = '/history',
  DASHBOARD = '/dashboard',
  GOVERNANCE = '/governance',
  HOME = '/',
  INVESTOR = '/investor',
  SETTINGS = '/settings',
  SMART_VAULTS = '/smart-vaults',
  STAKING = '/staking',
  VESTING = '/vesting',
  STRATEGIES = '/strategies',
}

export const CHAIN_URL = {
  [SupportedNetworks.ETHEREUM]: '/eth',
  [SupportedNetworks.ARBITRUM]: '/arb',
  [SupportedNetworks.SEPOLIA]: '/sep',
}

export const BaseRoutesPathsWithChain = (
  chainId: SupportedNetworks,
  baseRoutePath: BaseRoutesPaths
) => {
  return CHAIN_URL[chainId] + baseRoutePath
}

export type RouteDefinition = {
  url: string
  breadcrumbs?: string[]
}

type RouteGroup = {
  [key: string]: {
    [key: string]: RouteDefinition
  }
}

export const Routes: (chainId: SupportedNetworks) => RouteGroup = (
  chainId: SupportedNetworks
) => {
  return {
    home: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.HOME),
      },
    },
    staking: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.STAKING),
        breadcrumbs: [t('constants.routes.staking.breadcrumb')],
      },
      claim: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.STAKING
        )}/claim`,
        breadcrumbs: [
          t('constants.routes.staking.breadcrumb'),
          t('constants.routes.staking.earnings'),
          t('constants.routes.staking.claiming'),
        ],
      },
      claimStake: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.STAKING
        )}/claim/stake`,
        breadcrumbs: [
          t('constants.routes.staking.breadcrumb'),
          t('constants.routes.staking.earnings'),
          t('constants.routes.staking.claimingAndStaking'),
        ],
      },
      stake: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.STAKING
        )}/stake`,
        breadcrumbs: [
          t('constants.routes.staking.breadcrumb'),
          t('constants.routes.staking.breadcrumb'),
        ],
      },
      unstake: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.STAKING
        )}/unstake`,
        breadcrumbs: [
          t('constants.routes.staking.breadcrumb'),
          t('constants.routes.staking.unstaking'),
        ],
      },
    },
    createVault: {
      root: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_VAULT
        )}`,
      },
      createVaultRiskModel: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_VAULT
        )}/risk-model`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.vaultsManagement.createVault'),
        ],
      },
      createVaultStrategies: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_VAULT
        )}/strategies`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.vaultsManagement.createVault'),
        ],
      },
      createVaultAllocation: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_VAULT
        )}/allocation`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.vaultsManagement.createVault'),
        ],
      },
      createVaultLaunch: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_VAULT
        )}/launch`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.vaultsManagement.createVault'),
        ],
      },
      createVaultGuards: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_VAULT
        )}/guards`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.vaultsManagement.createVault'),
        ],
      },
      createVaultActions: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_VAULT
        )}/actions`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.vaultsManagement.createVault'),
        ],
      },
    },
    createMetavault: {
      root: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_METAVAULT
        )}`,
      },
      basicInfo: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_METAVAULT
        )}/basic-info`,
      },
      vaults: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_METAVAULT
        )}/vaults`,
      },
      allocation: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_METAVAULT
        )}/allocation`,
      },
      review: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.CREATE_METAVAULT
        )}/review`,
      },
    },
    smartVaults: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.SMART_VAULTS),
        breadcrumbs: [t('constants.routes.smartVaults.vaults')],
      },
      earnings: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.SMART_VAULTS
        )}/earnings`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.smartVaults.earnings'),
        ],
      },
      smartVault: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.SMART_VAULTS
        )}/:vaultId`,
        breadcrumbs: [t('constants.routes.smartVaults.vaults')],
        //breadcrumbs: [t('constants.routes.smartVaults.details')],
      },
    },
    governance: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.GOVERNANCE),
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.governance.breadcrumb'),
        ],
      },
      voting: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.GOVERNANCE
        )}/voting`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.governance.breadcrumb'),
        ],
      },
      votingWelcome: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.GOVERNANCE
        )}/voting/welcome`,
        breadcrumbs: [
          t('constants.routes.governance.breadcrumb'),
          t('constants.routes.governance.votingWelcome'),
        ],
      },
      votingResults: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.GOVERNANCE
        )}/voting/results`,
        breadcrumbs: [
          t('constants.routes.governance.breadcrumb'),
          t('constants.routes.governance.votingResults'),
        ],
      },
    },
    vesting: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.VESTING),
        breadcrumbs: [t('constants.routes.vesting.breadcrumb')],
      },
      claim: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.VESTING
        )}/claim`,
        breadcrumbs: [
          t('constants.routes.vesting.breadcrumb'),
          t('constants.routes.vesting.claim'),
        ],
      },
    },
    dashboard: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.DASHBOARD),
        breadcrumbs: [t('constants.routes.dashboard.breadcrumb')],
      },
      investor: {
        url:
          BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.DASHBOARD) +
          BaseRoutesPaths.INVESTOR,
        breadcrumbs: [
          t('constants.routes.myDeposits.title'),
          t('constants.routes.myDeposits.depositorDashboard'),
        ],
      },

      history: {
        url:
          BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.DASHBOARD) +
          BaseRoutesPaths.HISTORY,
        breadcrumbs: [
          t('constants.routes.myDeposits.title'),
          t('constants.routes.myDeposits.transactionHistory'),
        ],
      },
    },
    incentives: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.INCENTIVES),
        breadcrumbs: [t('constants.routes.incentives.incentivizeVault')],
      },
      details: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.INCENTIVES
        )}/:vaultId`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.incentives.incentivizeVault'),
        ],
      },
      add: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.INCENTIVES
        )}/:vaultId/add`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.incentives.incentivizeVault'),
          t('constants.routes.incentives.addToken'),
        ],
      },
      detailsToken: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.INCENTIVES
        )}/:vaultId/:tokenId`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.incentives.incentivizeVault'),
        ],
      },
      reincentivize: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.INCENTIVES
        )}/:vaultId/:tokenId/reincentivize`,
        breadcrumbs: [
          t('constants.routes.vaultsManagement.title'),
          t('constants.routes.incentives.incentivizeVault'),
          t('constants.routes.incentives.reIncentivize'),
        ],
      },
    },
    settings: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.SETTINGS),
        breadcrumbs: [t('constants.routes.settings.breadcrumb')],
      },
    },
    strategies: {
      root: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.STRATEGIES),
        breadcrumbs: [t('constants.routes.strategies.breadcrumb')],
      },
      details: {
        url: `${BaseRoutesPathsWithChain(
          chainId,
          BaseRoutesPaths.STRATEGIES
        )}/:strategyId`,
      },
    },
    manager: {
      creator: {
        url: BaseRoutesPathsWithChain(chainId, BaseRoutesPaths.CREATOR),
        breadcrumbs: [t('constants.routes.dashboard.creator')],
      },
    },
  }
}
