import { Box, TableBody } from '@mui/material'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import TableWrapper from '@/components/molecules/TableWrapper'
import ReviewTableRow from '@/components/organisms/CreateMetavault/Review/ReviewTableRow'

import { reviewHeadCells } from '@/constants/metavaults'

const ReviewTable = () => {
  const { selectedVaults } = useCreateMetavaultState()

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        backgroundColor: 'white',
        my: (theme) => theme.spacing(1),
      }}
    >
      <TableWrapper
        header={reviewHeadCells}
        headerClass='white-table-head'
        headerSX={{ py: 1.5 }}
      >
        {selectedVaults?.length && (
          <TableBody>
            {selectedVaults.map((vault, index) => (
              <ReviewTableRow key={vault.id} row={vault} index={index} />
            ))}
          </TableBody>
        )}
      </TableWrapper>
    </Box>
  )
}

export default ReviewTable
