import useAppState from '@/hooks/context/useAppState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useModalState from '@/hooks/context/useModalState'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

const useCreateMetaVault = () => {
  const { openModal, setModalType } = useModalState()

  const { sdkExternal } = useAppState()

  const {
    vaultName,
    vaultSymbol,
    asset,
    selectedVaults,
    selectedVaultsAllocations,
    resetAll,
    /*validateVaultName,
        validateVaultSymbol,*/
  } = useCreateMetavaultState()

  const handleCreateMetavault = async () => {
    const vaultAddresses = selectedVaults.map((vault) => vault.id)

    const allocations = selectedVaultsAllocations.map((allocation) =>
      Math.round(allocation * 100)
    )

    //const vaultNameValidation = validateVaultName(vaultName)
    //const vaultSymbolValidation = validateVaultSymbol(vaultSymbol)

    //if (!vaultNameValidation || !vaultSymbolValidation) return

    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.CreateMetavault,
        },
      })

      const tx = await sdkExternal.mutations.metaVaults.createMetaVault(
        asset,
        vaultName,
        vaultSymbol,
        {
          address: vaultAddresses,
          allocations: allocations,
        }
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.CreateMetavault,
        },
      })

      await tx.wait()

      resetAll()
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.CreateMetavault,
        },
      })
    } catch (err) {
      console.error(err)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.CreateMetavault,
        },
      })
    }
  }

  return handleCreateMetavault
}

export default useCreateMetaVault
