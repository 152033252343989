import { Stack } from '@mui/material'

import PageTransition from '@/components/atoms/PageTransition'

import VaultsOverview from '@/pages/SmartVaults/VaultsOverview'

const SmartVaults = () => {
  return (
    <PageTransition>
      <Stack gap={(theme) => theme.spacing(2)}>
        <VaultsOverview />
      </Stack>
    </PageTransition>
  )
}

export default SmartVaults
