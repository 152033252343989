import BoltIcon from '@mui/icons-material/Bolt'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { CreatorSmartVault, IncentivizeVault } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useUserClaimableNfts from '@/hooks/user/useUserClaimableNfts'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import CreatorVaultsTableRowExpand from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsTableRowExpand'
import CreatorVaultsTableRowOptions from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsTableRowOptions'
import CreatorVaultsTableRowWithdrawal from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsTableRowWithdrawal'
import YourBalanceToolTip from '@/components/organisms/Tooltips/YourBalanceToolTip'

import { Modals } from '@/store/modal/modals.types'

import { currency } from '@/constants/currency'
import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'
import { toDecimalPlaces } from '@/utils/helpers/toDecimalPlaces'

interface CreatorVaultsTableRowProps {
  row: CreatorSmartVault & IncentivizeVault
}

const CreatorVaultsTableRow: React.FC<CreatorVaultsTableRowProps> = ({
  row,
}) => {
  const { modals } = useModalState()

  const actionModalType = modals['actionModal']?.modalType

  const t = useLocale()
  const { chain } = useConnectionState()

  const { setIsVaultsRowClicked } = useUniversalNavigation()

  const { userClaimablewNFTs } = useUserClaimableNfts()

  const { connector, chainId } = useWeb3React()

  const { metrics } = useCreatorDashboard()

  const {
    name,
    address,
    assetGroup,
    adjustedApy,
    deposits,
    pendingDeposits,
    pendingWithdrawals,
    incentivizedRewards,
    userBalance,
  } = row

  const { selectedVaults, setSelectedVaults, setWithdrawEarningsVault } =
    useCreatorDashboardState()

  const { openModal, setModalType } = useModalState()

  const onRowClick = () => {
    setIsVaultsRowClicked(true)
  }

  const isSelected = useMemo(
    () => !!selectedVaults.find((vault) => vault.address === address),
    [selectedVaults, row]
  )

  const onDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const updatedVaults = isSelected
      ? selectedVaults.filter((vault) => vault.address !== address)
      : [...selectedVaults, row]

    setSelectedVaults(updatedVaults)
  }

  const hasActiveRewards = useMemo(
    () =>
      incentivizedRewards &&
      incentivizedRewards.rewards.some(({ endTime, startTime }) => {
        const endOfIncentiveProgress =
          100 -
          ((dayjs().unix() - Number(startTime)) * 100) /
            (Number(endTime) - Number(startTime))

        return endOfIncentiveProgress > 0
      }),
    [incentivizedRewards]
  )

  const vaultClaimableNFTs = useMemo(() => {
    if (!userClaimablewNFTs) return []

    return userClaimablewNFTs.filter(({ vaultId }) => vaultId === address)
  }, [userClaimablewNFTs])

  const performanceFeesEarned = useMemo(
    () => metrics.vaultFeesEarned[address].vaultPerformanceFees ?? '0',
    [metrics]
  )

  const managementFeesEarned = useMemo(
    () => metrics.vaultFeesEarned[address].vaultManagementFees ?? '0',
    [metrics]
  )

  return (
    <>
      <TableRow
        component={Link}
        to={`${Routes(chain).smartVaults.root.url}/${address}`}
        tabIndex={-1}
        onClick={onRowClick}
        sx={{
          '& > td': {
            borderBottom: 'unset',
          },
          backgroundColor: '#fff',
          userSelect: 'none',
        }}
      >
        <TableCell sx={{ border: 'unset' }}>
          <AssetGroupTokens assetGroup={assetGroup} />
        </TableCell>
        <TableCell sx={{ border: 'unset', paddingX: '0' }}>
          <Box display='flex' justifyContent='left'>
            <SmartVaultNameTableTypography variant='body2'>
              {name}
            </SmartVaultNameTableTypography>
            {hasActiveRewards && (
              <Tooltip title='Vault incentivized' arrow placement='top' sx={{}}>
                <BoltIcon fontSize='small' sx={{ color: '#4CAF50' }} />
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            {toDecimalPlaces(adjustedApy, 2)} %
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Box
            display='flex'
            justifyContent='right'
            alignItems='center'
            sx={{ position: 'relative' }}
          >
            <Box marginRight='0.5rem'>
              {(Number(pendingDeposits.totalUSD ?? '0') > 0 ||
                Number(pendingWithdrawals.total ?? '0') > 0) && (
                <Tooltip
                  title={
                    <YourBalanceToolTip
                      pendingDeposits={pendingDeposits.totalUSD ?? '0'}
                      pendingWithdrawals={pendingWithdrawals.total ?? '0'}
                    />
                  }
                  arrow
                >
                  <CurrencyExchangeIcon
                    className='sm'
                    sx={{
                      color: 'primary.light',
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <Box>
              <Typography variant='body2'>
                {currency} {formatUSD(deposits.total)}
              </Typography>
              {deposits.assets.map((asset, index) => {
                return (
                  <Typography key={index} fontSize='12px' color='gray'>
                    {asset.amount} {asset.asset.symbol}
                  </Typography>
                )
              })}
            </Box>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Typography
            variant='body2'
            color={`${+performanceFeesEarned > 0.099 ? '#2E7D32' : ''}`}
          >
            {+performanceFeesEarned > 0.099 && '+'}$
            {formatUSD(performanceFeesEarned ?? '0')}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography
            variant='body2'
            color={`${+managementFeesEarned > 0.099 ? '#2E7D32' : ''}`}
          >
            {+managementFeesEarned > 0.099 && '+'}$
            {formatUSD(managementFeesEarned ?? '0')}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='body2'>
            ${formatUSD(userBalance ?? '0')}
          </Typography>
        </TableCell>
        <TableCell align='center'>
          <Button
            variant='text'
            sx={{ textTransform: 'none' }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              event.preventDefault()
              if (!+userBalance) {
                setModalType({
                  warningModalType: {
                    message: t(
                      'components.organisms.dashboard.creator.creatorVaultsTable.row.warning.noEarnings'
                    ),
                  },
                  ...actionModalType,
                })
                openModal(Modals.WARNING_MODAL)
                return
              }

              if (chainId !== chain) {
                connector.activate(chain)
                return
              }
              setWithdrawEarningsVault(row)
              setModalType({
                warningModalType: {
                  message: t(
                    'components.organisms.dashboard.creator.creatorVaultsTable.row.warning.claimEarnings'
                  ),
                  isActionRequired: true,
                  action: () => openModal(Modals.WITHDRAW_MODAL),
                },
                ...actionModalType,
              })
              openModal(Modals.WARNING_MODAL)
            }}
          >
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.row.action'
            )}
          </Button>
        </TableCell>
        <TableCell align='right' sx={{ paddingX: '0' }}>
          <CreatorVaultsTableRowOptions row={row} />
        </TableCell>
        <TableCell align='right'>
          {!!incentivizedRewards?.rewards.length && (
            <IconButton
              size='small'
              onClick={(event) => onDropdownClick(event)}
            >
              {isSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <CreatorVaultsTableRowExpand
        row={row}
        vaultClaimableNFTs={vaultClaimableNFTs}
      />
      {(!!vaultClaimableNFTs?.length || !!Number(pendingWithdrawals.total)) && (
        <CreatorVaultsTableRowWithdrawal
          vaultClaimableNFTs={vaultClaimableNFTs}
          row={row}
        />
      )}
    </>
  )
}

export default CreatorVaultsTableRow
