const YelayLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 566 204'
      width='100%'
      height='100%'
    >
      <path
        fill='#001eff'
        strokeWidth='0px'
        d='M82.57,204l25.1-55.12h-6.55L59,48h30.34l30.34,76.04,31.64-76.04h29.68l-68.75,156h-29.68Z'
      />
      <path
        fill='#001eff'
        strokeWidth='0px'
        d='M230.87,159c-10.87,0-20.51-2.31-28.91-6.95-8.4-4.64-15-11.16-19.78-19.56-4.78-8.4-7.17-18.11-7.17-29.12s2.36-21.08,7.07-29.77c4.71-8.69,11.23-15.46,19.57-20.32,8.34-4.85,18.15-7.28,29.46-7.28,10.58,0,19.92,2.31,28.04,6.95,8.12,4.64,14.46,10.97,19.02,19.01,4.57,8.04,6.85,16.98,6.85,26.84,0,1.6-.03,3.26-.11,5-.08,1.74-.18,3.55-.33,5.43h-81.96c.58,8.4,3.51,14.99,8.8,19.77,5.29,4.78,11.71,7.17,19.24,7.17,5.65,0,10.4-1.27,14.24-3.8,3.84-2.53,6.71-5.84,8.59-9.89h28.26c-2.03,6.81-5.4,13.01-10.11,18.58-4.71,5.57-10.51,9.96-17.39,13.15-6.88,3.18-14.67,4.78-23.37,4.78ZM231.09,68.6c-6.82,0-12.83,1.92-18.04,5.76-5.22,3.84-8.55,9.67-10,17.49h53.7c-.44-7.1-3.04-12.75-7.83-16.95-4.78-4.2-10.73-6.3-17.83-6.3Z'
      />
      <path fill='#1a0d60' strokeWidth='0px' d='M298,156V0h28v156h-28Z' />
      <path
        fill='#1a0d60'
        strokeWidth='0px'
        d='M380.37,159c-9.26,0-16.85-1.49-22.79-4.46-5.94-2.97-10.34-6.92-13.24-11.84-2.9-4.92-4.34-10.35-4.34-16.3,0-10,3.91-18.11,11.72-24.34,7.81-6.23,19.53-9.34,35.16-9.34h27.34v-2.61c0-7.39-2.09-12.82-6.29-16.3-4.2-3.48-9.41-5.22-15.63-5.22-5.64,0-10.56,1.34-14.76,4.02-4.2,2.68-6.8,6.63-7.81,11.84h-27.13c.73-7.82,3.36-14.64,7.92-20.43,4.56-5.79,10.45-10.25,17.69-13.36,7.24-3.12,15.33-4.67,24.31-4.67,15.33,0,27.42,3.84,36.24,11.52,8.82,7.68,13.24,18.55,13.24,32.6v66.28h-23.65l-2.61-17.38c-3.18,5.79-7.63,10.57-13.35,14.34-5.72,3.77-13.05,5.65-22.03,5.65ZM386.66,137.27c7.95,0,14.14-2.61,18.55-7.82,4.42-5.21,7.19-11.66,8.36-19.34h-23.65c-7.38,0-12.66,1.34-15.84,4.02-3.18,2.68-4.77,5.98-4.77,9.89,0,4.21,1.6,7.46,4.77,9.78,3.18,2.31,7.38,3.48,12.59,3.48Z'
      />
      <path
        fill='#1a0d60'
        strokeWidth='0px'
        d='M467.57,204l25.1-55.12h-6.55l-42.12-100.88h30.34l30.34,76.04,31.64-76.04h29.68l-68.75,156h-29.68Z'
      />
      <path
        fill='#001eff'
        strokeWidth='0px'
        d='M71.47,148.88L29.46,48H0l42.01,100.88h6.53l-25.03,55.12h29.46l25.03-55.12h-6.53Z'
      />
    </svg>
  )
}

export default YelayLogo
