import { useContext } from 'react'

import { CreateMetavaultContext } from '@/store/createMetavault/createMetavault.context'

const useCreateMetavaultState = () => {
  const createMetavaultContext = useContext(CreateMetavaultContext)
  if (!Object.keys(createMetavaultContext).length) {
    throw new Error('CreateMetavaultContext must be used within its provider')
  }
  return createMetavaultContext
}

export default useCreateMetavaultState
