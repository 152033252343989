import WarningIcon from '@mui/icons-material/Warning'
import {
  Box,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@mui/material'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { useState } from 'react'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useMetavaultVaults from '@/hooks/metavaults/useMetavaultVaults'

import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import TableStatus from '@/components/molecules/TableStatus'
import TableWrapper from '@/components/molecules/TableWrapper'
import VaultSelectorTableRow from '@/components/organisms/CreateMetavault/Vaults/VaultSelectorTableRow'
import VaultSelectorTableRowMobile from '@/components/organisms/CreateMetavault/Vaults/VaultSelectorTableRowMobile'

import { eligibleVasultsHeadCells } from '@/constants/metavaults'
import { sort } from '@/utils/table'

const VaultSelectorTable = () => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const {
    eligibleVaults,
    isLoading,
  }: { eligibleVaults: CreateMetaVaultVaults[]; isLoading: boolean } =
    useMetavaultVaults()
  const { asset, vaultsSort, handleSortClick, searchString } =
    useCreateMetavaultState()

  const filteredVaults = eligibleVaults
    ? eligibleVaults
        .filter(
          (vault) =>
            !asset || vault.assetGroup.assetGroupTokens[0].address === asset
        )
        .filter(
          (vault) =>
            searchString === '' ||
            vault.name.toLowerCase().includes(searchString.toLowerCase())
        )
        .sort((a, b) => sort(a, b, vaultsSort))
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    : []

  return (
    <>
      {!belowPhabletBreakpoint && (
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
            backgroundColor: 'white',
            my: (theme) => theme.spacing(1),
          }}
        >
          <TableWrapper
            header={eligibleVasultsHeadCells}
            headerClass='white-table-head'
            headerSX={{ py: 1.5 }}
            handleSortClick={handleSortClick}
            sortType={vaultsSort}
            pagination={
              eligibleVaults?.length > 10 && {
                rowsPerPageOptions: [10, 20, 30],
                page: page,
                rowsPerPage: rowsPerPage,
                count: eligibleVaults?.length,
                setPage: setPage,
                setRowsPerPage: setRowsPerPage,
              }
            }
            whiteFooter
          >
            {isLoading ? (
              <TableBody>
                <TableStatus colSpan={4}>
                  <DataLoading loadingText='Loading Smart Vaults' />
                </TableStatus>
              </TableBody>
            ) : filteredVaults?.length ? (
              <TableBody>
                {filteredVaults.map((vault) => (
                  <VaultSelectorTableRow key={vault.id} row={vault} />
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box
                      my='2rem'
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <WarningIcon
                        sx={{ height: '90px', width: '90px' }}
                        color='primary'
                      />
                      <DataMissing text='No vaults found...' />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </TableWrapper>
        </Box>
      )}
      {belowPhabletBreakpoint && (
        <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1 }}>
          {isLoading ? (
            <DataLoading loadingText='Loading Smart Vaults' />
          ) : filteredVaults ? (
            filteredVaults.map((vault, index) => (
              <VaultSelectorTableRowMobile
                key={vault.id}
                row={vault}
                border={!!index}
              />
            ))
          ) : (
            <Box
              my='2rem'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <WarningIcon
                sx={{ height: '90px', width: '90px' }}
                color='primary'
              />
              <DataMissing text='No vaults found...' />
            </Box>
          )}
        </Box>
      )}
      {belowPhabletBreakpoint && (
        <TablePagination
          component='div'
          count={eligibleVaults?.length}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  )
}

export default VaultSelectorTable
