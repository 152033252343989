import WarningIcon from '@mui/icons-material/Warning'
import { Box } from '@mui/material'
import { CreatorTvrHistory } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'

import UnconnectedWalletDisplay from '@/components/atoms/UnconnectedWalletDisplay'
import ZeroDataDisplay from '@/components/atoms/ZeroDataDisplay'
import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import Section from '@/components/molecules/Section'
import CreatorAllocations from '@/components/organisms/Dashboard/Creator/CreatorAllocations'
import CreatorMetrics from '@/components/organisms/Dashboard/Creator/CreatorMetrics'
import CreatorVaultsTable from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsTable'

// import InvestorCharts from '@/components/organisms/Dashboard/Investor/InvestorCharts'
import Vaults from '@/assets/icons/menuIcons/Vaults'

import { Routes } from '@/constants/routes'
import { getHistoricalDatum } from '@/utils/accumulateHistoricalData'

const CreatorDashboard = () => {
  const { chain } = useConnectionState()

  const {
    creatorDashboard,
    vaults,
    tvrHistory,
    isLoading,
    totalVaults,
    error,
  } = useCreatorDashboard()
  const { account } = useWeb3React()
  const t = useLocale()

  const { setTvrChartValues } = useCreatorDashboardState()

  useEffect(() => {
    if (vaults) {
      const vaultsIds = vaults.map(({ address }) => address)

      const vaultsTvrHistory = Object.entries(tvrHistory).reduce(
        (acc, [key, value]) => {
          if (vaultsIds.includes(key)) {
            acc.push(value)
            return acc
          }

          return acc
        },
        [] as CreatorTvrHistory[][]
      )

      setTvrChartValues(getHistoricalDatum(vaultsTvrHistory, 'tvr'))
    }
  }, [vaults])

  return (
    <>
      {/* {creatorDashboard ? ( */}
      <>
        {/* <CreatorIncome /> */}

        {!account ? (
          <UnconnectedWalletDisplay />
        ) : error ? (
          <Box
            my='2rem'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <WarningIcon
              sx={{ height: '90px', width: '90px' }}
              color='primary'
            />
            <DataMissing text='An Error Occurred...' />
          </Box>
        ) : !isLoading && creatorDashboard ? (
          !totalVaults ? (
            <Section>
              <ZeroDataDisplay
                icon={
                  <Vaults
                    style={{
                      height: '55px',
                      width: '55px',
                      color: 'rgba(33, 35, 34, 0.38)',
                    }}
                  />
                }
                displayMessage={t(
                  'pages.smartVaults.vaultsOverview.zeroData.text'
                )}
                buttonText={t(
                  'pages.smartVaults.vaultsOverview.zeroData.buttonText'
                )}
                navigationRoute={Routes(chain).createVault.root.url}
                navigationRoute2={Routes(chain).createMetavault.root.url}
              />
            </Section>
          ) : (
            <Box display='flex' flexDirection='column' gap='1rem' pt={2}>
              <CreatorMetrics />
              <CreatorVaultsTable />
              <CreatorAllocations />
            </Box>
          )
        ) : (
          <DataLoading loadingText='Loading vaults data' />
        )}
      </>
      {/* ) : (
        <DataLoading loadingText='Loading creator data' />
      )} */}
    </>
  )
}

export default CreatorDashboard
