import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useLocation, useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { mapIcons } from '@/components/atoms/CryptoIcons'

import { IS_PUBLIC_TESTNET } from '@/config/sdk'
import { CHAINS } from '@/config/web3/chains'
import { swapUrlChain } from '@/utils/web3'

const NetworkSelector = () => {
  const { account, connector, chainId } = useWeb3React()
  const { chain, setChain } = useConnectionState()
  const { belowPhabletBreakpoint } = useBreakpointDetection()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { setSelectedVaultsAllocations, setSelectedVaults, setAsset } =
    useCreateMetavaultState()
  const t = useLocale()

  const handleChainSwitch = async (key: number) => {
    swapUrlChain(pathname, key, navigate)

    setChain(key)
    setSelectedVaults([])
    setSelectedVaultsAllocations([])
    setAsset('')

    if (chainId !== key && account) {
      connector.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: ethers.utils.hexValue(key),
          },
        ],
      })
    }
  }

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography variant='subtitle1'>
        {t('components.organisms.createMetavault.vaults.networkSelector.label')}
      </Typography>
      <ToggleButtonGroup
        value={chain}
        exclusive
        onChange={(event, newNetwork) => handleChainSwitch(newNetwork)}
        aria-label='network selector'
        sx={{
          display: 'flex',
          flexDirection: belowPhabletBreakpoint ? 'column' : 'row',
          gap: 1,
          px: 2,
          py: 1,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
        }}
      >
        {Object.keys(CHAINS)
          .filter((key) => {
            if (IS_PUBLIC_TESTNET) {
              return key === SupportedNetworks.SEPOLIA.toString()
            }
            return key !== SupportedNetworks.SEPOLIA.toString()
          })
          .map((key) => {
            const chainInfo = CHAINS[+key]
            return (
              <ToggleButton
                value={+key}
                key={key}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '4px !important',
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: 'capitalize',
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.primary.main,
                    backgroundColor: '#1AB7EA1F',
                    border: '1px solid !important',
                    borderColor: (theme) => theme.palette.primary.main,
                  },
                  '&:not(.Mui-selected)': {
                    backgroundColor: 'transparent',
                    color: 'inherit',
                    borderColor: 'transparent',
                  },
                  '&:hover': {
                    color: '#006BA6',
                    backgroundColor: '#fff',
                  },
                  width: '100%',
                  display: 'flex',
                  py: 3,
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: belowPhabletBreakpoint ? 'start' : 'center',
                }}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  sx={{ transform: 'scale(1.5)' }}
                >
                  {mapIcons(key)}
                </Box>
                <Box>{chainInfo.name}</Box>
              </ToggleButton>
            )
          })}
      </ToggleButtonGroup>
    </Box>
  )
}

export default NetworkSelector
