import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import getLocale from '@/locales/getLocale'

import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { ASSET_GROUPS } from '@/constants/createVault'

const AssetSelector = () => {
  const t = getLocale()

  const { chain } = useConnectionState()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { asset, setAsset, setSelectedVaults } = useCreateMetavaultState()

  const hasAddress = (
    asset:
      | { id: string; label: string }
      | {
          id: string
          label: string
          address: string
        }
  ): asset is { id: string; label: string; address: string } => {
    return 'address' in asset
  }

  const assetGroupsSingle = ASSET_GROUPS[chain].filter(
    (assetGroup) =>
      assetGroup.assets.length === 1 && assetGroup.assets.some(hasAddress)
  )

  const handleAssetSwitch = (
    event: React.MouseEvent<HTMLElement>,
    newAsset: string
  ) => {
    setSelectedVaults([])
    setAsset(newAsset)
  }

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography variant='subtitle1'>
        {t('components.organisms.createMetavault.vaults.assetSelector.label')}
      </Typography>
      <ToggleButtonGroup
        value={asset}
        exclusive
        onChange={(event, newAsset) => handleAssetSwitch(event, newAsset)}
        aria-label='asset selector'
        sx={{
          display: 'flex',
          flexDirection: belowPhabletBreakpoint ? 'column' : 'row',
          gap: 1,
          px: 2,
          py: 1,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
        }}
      >
        {assetGroupsSingle.map((assetGroup) => {
          const asset = assetGroup.assets[0]
          const assetAddress = hasAddress(asset) ? asset.address : '0'
          return (
            <ToggleButton
              value={assetAddress}
              key={asset.id}
              sx={{
                cursor: 'pointer',
                borderRadius: '4px !important',
                borderColor: (theme) => theme.palette.primary.main,
                textTransform: 'capitalize',
                '&.Mui-selected': {
                  color: (theme) => theme.palette.primary.main,
                  backgroundColor: '#1AB7EA1F',
                  border: '1px solid !important',
                  borderColor: (theme) => theme.palette.primary.main,
                },
                '&:not(.Mui-selected)': {
                  backgroundColor: 'transparent',
                  color: 'inherit',
                  borderColor: 'transparent',
                },
                '&:hover': {
                  color: '#006BA6',
                  backgroundColor: '#fff',
                },
                width: '100%',
                display: 'flex',
                py: 3,
                gap: 1,
                alignItems: 'center',
                justifyContent: belowPhabletBreakpoint ? 'start' : 'center',
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                sx={{ transform: 'scale(1.5)' }}
              >
                {mapAssetTokenIcon(chain, assetAddress)}
              </Box>
              <Box>{asset.label}</Box>
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </Box>
  )
}

export default AssetSelector
