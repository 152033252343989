import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material'
import {
  CreatorSmartVault,
  IncentivizeRewardToken,
  IncentivizeVault,
} from '@solidant/spool-v2-fe-lib'
import dayjs, { unix } from 'dayjs'

import useConnectionState from '@/hooks/context/useConnectionState'

import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import CreatorVaultsRewardsTableOptions from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsRewardsTableOptions'

import { gaIds } from '@/constants/googleAnalyticIDs'
import { formatUSD } from '@/utils/formats'

interface CreatorVaultsRewardsTableRowProps {
  incentivizeReward: IncentivizeRewardToken
  row: CreatorSmartVault & IncentivizeVault
}

const CreatorVaultsRewardsTableRow: React.FC<
  CreatorVaultsRewardsTableRowProps
> = ({ incentivizeReward, row }) => {
  const { chain } = useConnectionState()

  const { totalAmount, asset, remainingAmount, endTime, startTime } =
    incentivizeReward

  const currentIncentivizedDays = unix(Number(endTime)).diff(dayjs(), 'day') + 1
  const totalIncentivizedDays =
    unix(Number(endTime)).diff(unix(Number(startTime)), 'day') + 1

  const endOfIncentiveProgress =
    100 -
    ((dayjs().unix() - Number(startTime)) * 100) /
      (Number(endTime) - Number(startTime))

  const incentiveFinished = endOfIncentiveProgress < 0

  return (
    <TableRow
      id={
        gaIds.smartVaultRow.main + '-' + gaIds.smartVaultRow.investorDashboard
      }
    >
      <TableCell>
        <Stack width='182px'>
          <Box display='flex' alignItems='center' gap='4px'>
            <Box sx={{ transform: 'scale(1.2)' }} display='flex'>
              {mapAssetTokenIcon(chain, asset.address ?? '')}
            </Box>
            <Typography variant='body2'>{asset.symbol}</Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell align='right' sx={{ width: '100px' }}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='right'
          gap='4px'
        >
          {formatUSD(remainingAmount)}/{formatUSD(totalAmount.toString())}{' '}
          <Typography variant='body2'>{asset.symbol}</Typography>
          <Box display='flex'>
            {mapAssetTokenIcon(chain, asset.address ?? '')}
          </Box>
        </Box>
      </TableCell>
      <TableCell align='right' sx={{ width: '200px' }}>
        {
          // if currentIncentivizedDays is negative, show 0
          endOfIncentiveProgress < 0 ? 0 : currentIncentivizedDays
        }
        /{totalIncentivizedDays} Days
      </TableCell>
      <TableCell sx={{ width: '90px' }}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Box
            display='flex'
            alignItems='center'
            padding='0.5rem'
            height='2rem'
            borderRadius='4px'
            bgcolor={incentiveFinished ? '#F5F5F5' : '#E8F5E9'}
            color={incentiveFinished ? '#757575' : '#2E7D32'}
          >
            <Typography fontSize='30px'>•</Typography>
            <Typography fontSize='14px'>
              {incentiveFinished ? 'Finished' : 'Active'}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      {endOfIncentiveProgress < 0 && (
        <TableCell sx={{ width: '40px' }}>
          <CreatorVaultsRewardsTableOptions
            row={row}
            lastIncentivizedDays={totalIncentivizedDays.toString()}
            targetToken={asset.address}
            tokenAmount={totalAmount.toString()}
            incentivizeReward={incentivizeReward}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

export default CreatorVaultsRewardsTableRow
