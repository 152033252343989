import { SortOrder } from '@solidant/spool-v2-fe-lib'

import { initialState } from '@/store/createMetavault/createMetavault.state'
import {
  CreateMetavaultAction,
  CreateMetavaultActionTypes,
  CreateMetavaultState,
} from '@/store/createMetavault/createMetavault.types'

const createMetavaultReducer = (
  state: CreateMetavaultState,
  action: CreateMetavaultAction
): CreateMetavaultState => {
  switch (action.type) {
    case CreateMetavaultActionTypes.SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload }
    case CreateMetavaultActionTypes.SET_STEP_COMPLETED:
      return {
        ...state,
        steps: state.steps.map((step, index) =>
          index === action.payload.stepNumber - 1
            ? { ...step, completed: action.payload.status }
            : step
        ),
      }
    case CreateMetavaultActionTypes.SET_ASSET:
      return { ...state, asset: action.payload }
    case CreateMetavaultActionTypes.SET_SELECTED_VAULTS:
      return { ...state, selectedVaults: action.payload }
    case CreateMetavaultActionTypes.SET_VAULTS_SORT:
      return { ...state, vaultsSort: action.payload }
    case CreateMetavaultActionTypes.CHANGE_VAULTS_SORT_DIRECTION:
      return {
        ...state,
        vaultsSort: {
          key: action.payload.key,
          direction:
            state.vaultsSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case CreateMetavaultActionTypes.SET_SELECTED_VAULTS_ALLOCATIONS:
      return { ...state, selectedVaultsAllocations: action.payload }
    case CreateMetavaultActionTypes.SET_VAULT_NAME:
      return { ...state, vaultName: action.payload }
    case CreateMetavaultActionTypes.SET_VAULT_SYMBOL:
      return { ...state, vaultSymbol: action.payload }
    case CreateMetavaultActionTypes.RESRET_ALL:
      return { ...state, ...initialState }
    case CreateMetavaultActionTypes.SET_SEARCH_STRING:
      return { ...state, searchString: action.payload }
  }
}

export default createMetavaultReducer
