import { ReactElement } from 'react'

import Allocation from '@/components/organisms/CreateMetavault/Allocation/Allocation'
import BasicInfo from '@/components/organisms/CreateMetavault/BasicInfo/BasicInfo'
import Review from '@/components/organisms/CreateMetavault/Review/Review'
import Vaults from '@/components/organisms/CreateMetavault/Vaults/Vaults'

type stepsMap = {
  [key: number]: ReactElement
}

interface CreateMetavaultRenderStepProps {
  step: number
}

const CreateMetavaultRenderStep: React.FC<CreateMetavaultRenderStepProps> = ({
  step,
}) => {
  const renderStepsMap: stepsMap = {
    1: <BasicInfo />,
    2: <Vaults />,
    3: <Allocation />,
    4: <Review />,
  }

  return renderStepsMap[step]
}

export default CreateMetavaultRenderStep
