import { Box } from '@mui/material'

import AssetSelector from '@/components/organisms/CreateMetavault/Vaults/AssetSelector'
import NetworkSelector from '@/components/organisms/CreateMetavault/Vaults/NetworkSelector'
import VaultSelector from '@/components/organisms/CreateMetavault/Vaults/VaultSelector'

const Vaults = () => {
  return (
    <Box display='flex' flexDirection='column' gap={6} sx={{ pb: 12 }}>
      <NetworkSelector />
      <AssetSelector />
      <VaultSelector />
    </Box>
  )
}

export default Vaults
