import {
  alpha,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { BigNumber } from 'ethers'
import { useState } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import getLocale from '@/locales/getLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { currency } from '@/constants/currency'
import { formatUSD } from '@/utils/formats'

interface AllocationTableRowMobileProps {
  row: CreateMetaVaultVaults
  index: number
}

const AllocationTableRowMobile: React.FC<AllocationTableRowMobileProps> = ({
  row,
  index,
}) => {
  const t = getLocale()

  const { selectedVaultsAllocations, setSelectedVaultsAllocations } =
    useCreateMetavaultState()

  const { chain } = useConnectionState()

  const { name, tvr, apy, assetGroup } = row

  const [inputValue, setInputValue] = useState(selectedVaultsAllocations[index])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (
      value !== '.' &&
      +value < Number.MAX_SAFE_INTEGER &&
      value.match('^[0-9]*[.]?[0-9]*$')
    ) {
      setInputValue(+value)
      const updatedAllocations = [...selectedVaultsAllocations]
      updatedAllocations[index] = +value
      setSelectedVaultsAllocations(updatedAllocations)
    }
  }

  const isError = BigNumber.from(inputValue).gt(BigNumber.from(100))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        px: 2,
        py: 1.5,
        borderTop: index > 0 ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
      }}
    >
      <Box display='flex' alignItems='center' justifyContent='start' gap={1.5}>
        <Box
          sx={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            background: alpha(
              '#039BE5',
              1 - (index / selectedVaultsAllocations.length) * 0.9
            ),
          }}
        />
        <Box
          display='flex'
          alignItems='center'
          sx={{ transform: 'scale(1.5)' }}
        >
          {mapAssetTokenIcon(chain, assetGroup.assetGroupTokens[0].address)}
        </Box>
        <SmartVaultNameTableTypography variant='body2'>
          {name}
        </SmartVaultNameTableTypography>
      </Box>

      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between' sx={{ py: 1 }}>
          <Typography variant='body2' color='rgba(33, 35, 34, 0.6)'>
            7D APY
          </Typography>
          <Typography variant='body2'>{formatUSD(apy || 0)} %</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' sx={{ py: 1 }}>
          <Typography variant='body2' color='rgba(33, 35, 34, 0.6)'>
            TVR
          </Typography>
          <Typography variant='body2'>
            {currency} {formatUSD(tvr.totalTvr || 0)}
          </Typography>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ py: 1 }}
        >
          <Typography variant='body2' color='rgba(33, 35, 34, 0.6)'>
            Allocation
          </Typography>
          <TextField
            size='small'
            value={inputValue}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            }}
            sx={{ width: '80px', textAlign: 'right' }}
            error={isError}
          />
        </Box>
      </Box>

      {isError && (
        <Typography variant='body2' textAlign='right' color='error'>
          {t('components.organisms.createMetavault.allocation.errors.row')}
        </Typography>
      )}
    </Box>
  )
}

export default AllocationTableRowMobile
