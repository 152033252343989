import { Box, TableCell, TableRow, Typography } from '@mui/material'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { formatUSD } from '@/utils/formats'

interface ReviewTableRowProps {
  row: CreateMetaVaultVaults
  index: number
}

const ReviewTableRow: React.FC<ReviewTableRowProps> = ({ row, index }) => {
  const { chain } = useConnectionState()

  const { selectedVaultsAllocations } = useCreateMetavaultState()

  const { name, assetGroup } = row

  return (
    <>
      <TableRow>
        <TableCell sx={{ py: 2 }}>
          <Box
            display='flex'
            justifyContent='start'
            alignItems='center'
            gap={1}
          >
            {mapAssetTokenIcon(chain, assetGroup.assetGroupTokens[0].address)}
            <SmartVaultNameTableTypography>
              {name}
            </SmartVaultNameTableTypography>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant='body2' textAlign='right'>
            {formatUSD(selectedVaultsAllocations[index])} %
          </Typography>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ReviewTableRow
