import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useCreateMetavault from '@/hooks/metavaults/useCreateMetavault'

import { Routes } from '@/constants/routes'

const CreateMetavaultNavigation = () => {
  const navigate = useNavigate()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { chain } = useConnectionState()

  const {
    currentStep,
    setCurrentStep,
    setStepCompletedStatus,
    vaultName,
    vaultSymbol,
    selectedVaults,
    selectedVaultsAllocations,
    setSelectedVaultsAllocations,
    resetAll,
  } = useCreateMetavaultState()

  const handleCreateMetavault = useCreateMetavault()

  const handleClickBack = () => {
    setStepCompletedStatus(currentStep, false)
    setCurrentStep(currentStep - 1)
  }

  const handleClickNext = () => {
    if (currentStep === 2) {
      const allocations = Array(selectedVaults.length).fill(
        Math.floor(100 / selectedVaults.length)
      )
      setSelectedVaultsAllocations(allocations)
    }
    setStepCompletedStatus(currentStep, true)
    setCurrentStep(currentStep + 1)
  }

  const handleClickCancel = () => {
    resetAll()
    navigate(Routes(chain).manager.creator.url)
  }

  const handleClickDeploy = () => {
    handleCreateMetavault()
  }

  const handleDisable = () => {
    switch (currentStep) {
      case 1:
        return !vaultName || !vaultSymbol
      case 2:
        return selectedVaults.length === 0
      case 3:
        return (
          selectedVaultsAllocations.reduce((acc, cur) => acc + cur, 0) !== 100
        )
      case 4:
        return false
      default:
        return false
    }
  }

  return (
    <Box
      width='100%'
      position='fixed'
      bottom={0}
      zIndex={1000}
      borderTop='1px solid rgba(0, 0, 0, 0.12)'
      sx={{ py: 2, background: 'white' }}
    >
      <Box
        maxWidth='1250px'
        mx='auto'
        display='flex'
        justifyContent={currentStep !== 1 ? 'space-between' : 'flex-end'}
        sx={{ px: belowPhabletBreakpoint ? 2 : 0 }}
      >
        {currentStep !== 1 && (
          <Button
            startIcon={<ArrowBackIcon />}
            variant='outlined'
            onClick={() => handleClickBack()}
          >
            back
          </Button>
        )}
        <Box display='flex' gap={1}>
          <Button onClick={() => handleClickCancel()}>cancel</Button>
          {currentStep !== 4 ? (
            <Button
              sx={{ px: 3 }}
              endIcon={<ArrowForwardIcon />}
              variant='contained'
              onClick={() => handleClickNext()}
              disabled={handleDisable()}
            >
              next
            </Button>
          ) : (
            <Button
              sx={{ px: 3 }}
              onClick={() => handleClickDeploy()}
              variant='contained'
              disabled={handleDisable()}
            >
              deploy
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CreateMetavaultNavigation
