import { Box, Stack } from '@mui/material'

import CreateVaultButton from '@/components/atoms/CreateVaultButton'
import DataMissing from '@/components/molecules/DataMissing'
import SectionColumn from '@/components/molecules/SectionColumn'

interface ZeroDataDisplayProps {
  icon?: React.ReactNode
  displayMessage: string
  buttonText: string
  navigationRoute: string
  navigationRoute2?: string
}

const ZeroDataDisplay: React.FC<ZeroDataDisplayProps> = ({
  icon,
  displayMessage,
  buttonText,
  navigationRoute,
  navigationRoute2,
}) => {
  return (
    // <Section>
    <SectionColumn sx={{ gap: 0 }}>
      <Stack gap={2} sx={{ height: '425px' }}>
        <Box display='flex' flexDirection='column' sx={{ my: 'auto' }}>
          {icon && (
            <Box
              display='flex'
              alignItems='center'
              gap={2}
              margin='auto'
              sx={{ pb: 1 }}
            >
              {icon}
            </Box>
          )}
          <DataMissing text={displayMessage} />
          <Box display='flex' alignItems='center' margin='auto' sx={{ py: 2 }}>
            <CreateVaultButton
              buttonText={buttonText}
              navigationRoute={navigationRoute}
              navigationRouteMetavault={navigationRoute2}
            />
          </Box>
        </Box>
      </Stack>
    </SectionColumn>
    // </Section>
  )
}

export default ZeroDataDisplay
