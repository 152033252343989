import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography } from '@mui/material'

import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import getLocale from '@/locales/getLocale'

import SearchField from '@/components/organisms/CreateMetavault/Vaults/SearchField'
import VaultSelectorTable from '@/components/organisms/CreateMetavault/Vaults/VaultSelectorTable'

import { MAX_METAVAULTSELECTION } from '@/constants/metavaults'

const VaultSelector: React.FC = () => {
  const t = getLocale()
  const { belowPhabletBreakpoint } = useBreakpointDetection()
  const { selectedVaults } = useCreateMetavaultState()

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='subtitle1'>
          {t('components.organisms.createMetavault.vaults.vaultSelector.label')}{' '}
          ({selectedVaults.length}/{MAX_METAVAULTSELECTION})
        </Typography>
        {!belowPhabletBreakpoint && <SearchField />}
      </Box>
      <Box
        display='flex'
        gap={0.5}
        alignItems='center'
        pb={2}
        pt={belowPhabletBreakpoint ? 2 : 4}
      >
        <InfoOutlinedIcon sx={{ color: 'text.secondary' }} fontSize='small' />
        <Typography variant='body2' color='text.secondary'>
          {t('components.organisms.createMetavault.vaults.vaultSelector.info')}
        </Typography>
      </Box>
      {belowPhabletBreakpoint && (
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 1,
            p: 2,
            mb: 3,
          }}
        >
          <SearchField />
        </Box>
      )}
      <VaultSelectorTable />
    </Box>
  )
}

export default VaultSelector
