import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { SWRConfig } from 'swr/_internal'

import './index.css'

import ErrorBoundary from '@/components/molecules/ErrorBoundry'

import { AppProvider } from '@/store/app/app.provider'
import { ConnnectionProvider } from '@/store/connection/connection.provider'
import { CreateMetavaultProvider } from '@/store/createMetavault/createMetavault.provider'
import { CreateVaultProvider } from '@/store/createVault/createVault.provider'
import { CreatorDashboardProvider } from '@/store/creatorVault/creatorDashboard.provider'
import { DepositProvider } from '@/store/deposit/deposit.provider'
import { IncentivizeVaultProvider } from '@/store/incentiviseVault/incentiviseVault.provider'
import { ModalProvider } from '@/store/modal/modals.provider'
import Web3Provider from '@/store/web3/web3.provider'
import { WithdrawProvider } from '@/store/withdraw/withdraw.provider'

import App from '@/App'
import MainTheme from '@/themes/MainTheme'

const development = true
const StrictMode = development ? React.StrictMode : React.Fragment

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ErrorBoundary>
      <SWRConfig
        value={{
          errorRetryCount: 5,
          dedupingInterval: 60000,
        }}
      >
        <Web3Provider>
          <ConnnectionProvider>
            <AppProvider>
              <ModalProvider>
                <MainTheme>
                  <CreateVaultProvider>
                    <CreateMetavaultProvider>
                      <IncentivizeVaultProvider>
                        <DepositProvider>
                          <WithdrawProvider>
                            <CreatorDashboardProvider>
                              <App />
                            </CreatorDashboardProvider>
                          </WithdrawProvider>
                        </DepositProvider>
                      </IncentivizeVaultProvider>
                    </CreateMetavaultProvider>
                  </CreateVaultProvider>
                </MainTheme>
              </ModalProvider>
            </AppProvider>
          </ConnnectionProvider>
        </Web3Provider>
      </SWRConfig>
    </ErrorBoundary>
  </StrictMode>
)
