import WarningIcon from '@mui/icons-material/Warning'
import { Box, TableBody, TableCell, TableRow } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import CreateVaultButton from '@/components/atoms/CreateVaultButton'
import DataMissing from '@/components/molecules/DataMissing'
import SectionColumn from '@/components/molecules/SectionColumn'
import TableWrapper from '@/components/molecules/TableWrapper'
import CreatorVaultsMobileRow from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsMobileRow'
import CreatorVaultsTableRow from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsTableRow'

import { creatorDashboardHeadCells } from '@/constants/dashboard'
import { Routes } from '@/constants/routes'
import { sort } from '@/utils/table'

const CreatorVaultsTable = () => {
  const { belowTabletBreakpoint } = useBreakpointDetection()

  const {
    creatorVaultsTableLimit,
    creatorVaultsTablePage,
    creatorVaultsTableSort,
    handleSortClick,
    setCreatorVaultsTableLimit,
    setCreatorVaultsTablePage,
  } = useCreatorDashboardState()
  const { vaults } = useCreatorDashboard()
  const { chain } = useConnectionState()
  const t = useLocale()

  return !belowTabletBreakpoint ? (
    <>
      {vaults.length ? (
        <SectionColumn
          title={t(
            'components.organisms.dashboard.creator.creatorVaultsTable.title'
          )}
          sx={{ px: 0, gap: 1 }}
          noPadding
          actionButtons={
            <CreateVaultButton
              buttonText={t(
                'components.organisms.topMenu.b2b.createVaultButton.title'
              )}
              navigationRoute={Routes(chain).createVault.root.url}
              navigationRouteMetavault={Routes(chain).createMetavault.root.url}
            />
          }
        >
          <Box
            border='1px solid rgba(0, 0, 0, 0.12)'
            borderRadius='4px'
            overflow='hidden'
            sx={{ mt: 1 }}
          >
            <TableWrapper
              tokens
              header={creatorDashboardHeadCells}
              headerClass='default-table-head'
              pagination={
                vaults.length > 10 && {
                  rowsPerPageOptions: [10, 20, 30],
                  page: creatorVaultsTablePage,
                  rowsPerPage: creatorVaultsTableLimit,
                  count: vaults.length,
                  setPage: setCreatorVaultsTablePage,
                  setRowsPerPage: setCreatorVaultsTableLimit,
                }
              }
              handleSortClick={handleSortClick}
              sortType={creatorVaultsTableSort}
            >
              <TableBody>
                {vaults
                  .sort((a, b) => sort(a, b, creatorVaultsTableSort))
                  .slice(
                    creatorVaultsTablePage * creatorVaultsTableLimit,
                    (creatorVaultsTablePage + 1) * creatorVaultsTableLimit
                  )
                  .map((creatorVault) => (
                    <CreatorVaultsTableRow
                      key={creatorVault.address}
                      row={creatorVault}
                    />
                  ))}
              </TableBody>
            </TableWrapper>
          </Box>
        </SectionColumn>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <Box
                my='2rem'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <WarningIcon
                  sx={{ height: '90px', width: '90px' }}
                  color='primary'
                />
                <DataMissing text='Creator Vaults not found...' />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </>
  ) : (
    <SectionColumn
      title={t(
        'components.organisms.dashboard.creator.creatorVaultsTable.title'
      )}
      sx={{ px: 0 }}
      actionButtons={
        <CreateVaultButton
          buttonText={t(
            'components.organisms.topMenu.b2b.createVaultButton.title'
          )}
          navigationRoute={Routes(chain).createVault.root.url}
          navigationRouteMetavault={Routes(chain).createMetavault.root.url}
        />
      }
    >
      <Box pt={2}>
        {vaults
          .sort((a, b) => sort(a, b, creatorVaultsTableSort))
          .slice(
            creatorVaultsTablePage * creatorVaultsTableLimit,
            (creatorVaultsTablePage + 1) * creatorVaultsTableLimit
          )
          .map((creatorVault) => (
            <CreatorVaultsMobileRow
              key={creatorVault.address}
              row={creatorVault}
            />
          ))}
      </Box>
    </SectionColumn>
  )
}

export default CreatorVaultsTable
