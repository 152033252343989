import { Box, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateMetavaultState from '@/hooks/context/useCreateMetavaultState'

import getLocale from '@/locales/getLocale'

import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import ReviewTable from '@/components/organisms/CreateMetavault/Review/ReviewTable'

const Review = () => {
  const t = getLocale()
  const { chain } = useConnectionState()
  const { vaultName, vaultSymbol, asset, selectedVaults } =
    useCreateMetavaultState()

  return (
    <Box display='flex' flexDirection='column' gap={4} sx={{ pb: 12 }}>
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='subtitle1'>
          {t('components.organisms.createMetavault.review.basicInfo.title')}
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 1,
          }}
        >
          <Box display='flex' justifyContent='space-between' p={2}>
            <Typography variant='body2'>
              {t('components.organisms.createMetavault.review.basicInfo.name')}
            </Typography>
            <Typography variant='body2'>{vaultName}</Typography>
          </Box>
          <Box display='flex' justifyContent='space-between' p={2} pt={0}>
            <Typography variant='body2'>
              {t(
                'components.organisms.createMetavault.review.basicInfo.symbol'
              )}
            </Typography>
            <Typography variant='body2'>{vaultSymbol}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='subtitle1'>
          {t('components.organisms.createMetavault.review.asset.title')}
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 1,
          }}
        >
          <Box display='flex' p={2} gap={1}>
            {mapAssetTokenIcon(chain, asset)}
            <Typography variant='body2'>
              {selectedVaults[0].assetGroup.assetGroupTokens[0].symbol}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='subtitle1'>
          {t('components.organisms.createMetavault.review.vaults.title')}
        </Typography>
        <ReviewTable />
      </Box>
    </Box>
  )
}

export default Review
