import { Box } from '@mui/material'

import useModalState from '@/hooks/context/useModalState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import DialogContainer from '@/components/organisms/Dialogs/DialogContainer'
import ButtonConfirm from '@/components/organisms/Modals/ActionModal/ButtonConfirm'
import ButtonMaximize from '@/components/organisms/Modals/ActionModal/ButtonMaximize'
import ButtonMinimize from '@/components/organisms/Modals/ActionModal/ButtonMinimize'
import CreateMetavaultSuccess from '@/components/organisms/Modals/ActionModal/CreateMetavaultSuccess'
import DepositSuccess from '@/components/organisms/Modals/ActionModal/DepositSuccess'
import FastWithdrawSuccess from '@/components/organisms/Modals/ActionModal/FastWithdrawSuccess'
import ModalText from '@/components/organisms/Modals/ActionModal/ModalText'
import WithdrawSuccess from '@/components/organisms/Modals/ActionModal/WithdrawSuccess'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { TransactionStatus, TransactionType } from '@/store/modal/modals.types'

import { gaIds } from '@/constants/googleAnalyticIDs'

const ActionModal: React.FC = () => {
  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { modals } = useModalState()

  const modalType = modals['actionModal'].modalType?.actionModalType

  const modalWithdrawSuccess =
    modalType?.transactionStatus === TransactionStatus.Success &&
    modalType?.transactionType === TransactionType.Withdraw

  const modalDepositSuccess =
    modalType?.transactionStatus === TransactionStatus.Success &&
    modalType?.transactionType === TransactionType.Deposit

  const modalFastWithdrawSuccess =
    modalType?.transactionStatus === TransactionStatus.Success &&
    modalType?.transactionType === TransactionType.FastWithdraw

  const modalCreateMetavaultSuccess =
    modalType?.transactionStatus === TransactionStatus.Success &&
    modalType?.transactionType === TransactionType.CreateMetavault

  const content = modalWithdrawSuccess ? (
    <WithdrawSuccess />
  ) : modalDepositSuccess ? (
    <DepositSuccess />
  ) : modalFastWithdrawSuccess ? (
    <FastWithdrawSuccess />
  ) : modalCreateMetavaultSuccess ? (
    <CreateMetavaultSuccess />
  ) : (
    <Box
      display='flex'
      flexDirection={!modals['actionModal'].isMinimized ? 'column' : 'row'}
      justifyContent='center'
      gap={2}
      width={belowPhabletBreakpoint ? '260px' : '100%'}
      px={2}
      py={modals['actionModal'].isMinimized ? 1 : 0}
    >
      <ModalText
        textId={
          modalType?.transactionStatus === TransactionStatus.Failure ||
          modalType?.transactionStatus === TransactionStatus.Success
            ? gaIds.actionModal
            : ''
        }
        modalType={modalType}
        isMinimized={modals['actionModal'].isMinimized}
      />
      {(modalType?.transactionStatus === TransactionStatus.Processing ||
        modalType?.transactionStatus === TransactionStatus.PendingSignature) &&
        (!modals['actionModal'].isMinimized ? (
          <ButtonMinimize />
        ) : (
          <ButtonMaximize />
        ))}
      {modalType?.transactionStatus !== TransactionStatus.Processing &&
        modalType?.transactionStatus !== TransactionStatus.PendingSignature && (
          <ButtonConfirm />
        )}
    </Box>
  )

  if (
    modals['actionModal'].isMinimized &&
    !modalWithdrawSuccess &&
    !modalDepositSuccess
  ) {
    return (
      <DialogContainer isOpen={modals['actionModal'].isOpen} padding={0}>
        {content}
      </DialogContainer>
    )
  }

  return (
    <ModalBox id='actionModal' disabledClose>
      <ModalBoxOuterContainer>{content}</ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default ActionModal
